import Button from 'components/Button';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Main from 'components/Main';
import { NavigateToDatasetButton } from 'components/NavigateToDatasetButton';
import PageHeader from 'components/PageHeader';
import usePagination from 'hooks/usePagination';
import {
  getDynamicTagsAsTableQuery,
  useGetCategoryById,
} from 'queries/dynamic-tags';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PaginationState } from 'types/pagination';
import { DynamicTagTableView } from './components/DynamicTagTableView';
import { EmptyErrorState } from '../components/ErrorEmptyState';

const DynamicTagCategoryPage: React.FunctionComponent<{}> =
  function DynamicTagCategoryPage() {
    const { categoryId } = useParams();
    const { data: category, isError } = useGetCategoryById(categoryId);

    const pagination = usePagination();
    const navigate = useNavigate();
    const {
      data: dynamicTags,
      isLoading,
      isFetched,
    } = getDynamicTagsAsTableQuery({
      categoryId: categoryId || '',
      limit: pagination.size,
      offset: (pagination.page - 1) * pagination.size,
    });

    const paginationState = useMemo<PaginationState>(
      () => ({ page: pagination.page, size: pagination.size }),
      [pagination.page, pagination.size],
    );
    const loadingTable = useMemo(() => <LoadingTable />, []);
    const loading = isLoading && !isFetched;

    // handle case when prompt object is null
    const parsedResponseData = dynamicTags
      ? dynamicTags.data.map((tableRow) => ({
          ...tableRow,
          data: {
            ...tableRow.data,
            textPrompts: tableRow.data.textPrompts
              ? tableRow.data.textPrompts.map((prompt) => prompt.text)
              : [],
            visualPrompts: tableRow.data.visualPrompts || [],
          },
        }))
      : [];

    const breadcrumbs = [
      {
        label: 'Categories',
        to: '/dynamic-tag-categories',
      },
      {
        label: `${category?.name || 'Category'}: Dynamic tags`,
        to: '',
      },
    ];

    // TODO: fix styling of page header so that the gap between breadcrumbs and
    // title is smaller
    const title = (
      <div className="mt-[-1rem] flex">
        <h1>{category?.name || 'Category'}</h1>
        <div className="mx-3">
          <NavigateToDatasetButton
            // TODO: fix to handle multiple datasets
            datasetName={category?.datasets[0].name || ''}
            path={`/datasets/${category?.datasets[0].id}`}
          />
        </div>
      </div>
    );

    return (
      <Main loading={!category?.name || isLoading}>
        <div className="max-w-8xl mx-auto">
          <PageHeader
            title={title}
            breadcrumbs={breadcrumbs}
            backButtonTo=""
            actions={
              <Button onClick={() => navigate('dynamic-tag/add')}>
                Add dynamic tag
              </Button>
            }
          />
          {isError ? (
            <div className="h-[80vh] flex items-center justify-center">
              <EmptyErrorState itemWithError="dynamic tags" />
            </div>
          ) : (
            <div className="pt-8">
              <FadeTransition show={Boolean(!loading && dynamicTags)} appear>
                {dynamicTags && (
                  <DynamicTagTableView
                    dynamicTags={parsedResponseData}
                    pagination={pagination}
                    total={dynamicTags.meta.page.total || 0}
                    columns={dynamicTags.columns}
                    isLoading={isLoading}
                    paginationState={paginationState}
                  />
                )}
              </FadeTransition>
              <FadeTransition
                show={loading}
                appear
                enterDelayMs={500}
                exit={false}
              >
                {loading && loadingTable}
              </FadeTransition>
            </div>
          )}
        </div>
      </Main>
    );
  };

export default DynamicTagCategoryPage;
