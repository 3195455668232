import { useAuth0 } from '@auth0/auth0-react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import CoactiveLogo from 'assets/logos/coactive.svg';
import SidebarContext from 'context/SidebarContext';
import { useUserContext } from 'context/UserContext';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'styles/toastify.scss';
import { SidebarAssetContextWrapper } from 'context/SidebarAssetContext';
import { buildUserContext, evaluateFlag } from 'feature_flags/FeatureFlags';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import ProfileMenu from './ProfileMenu';
import Banner from './Banner';

interface NavigationItem {
  name: string;
  hrefs: string[];
  current: boolean;
  external?: boolean;
  hideForDynamicTagFF?: boolean;
  hideForTrialUsers?: boolean;
  dataTestId?: string;
}

const navigation: NavigationItem[] = [
  { name: 'Datasets', hrefs: ['/', '/datasets'], current: false },
  {
    name: 'Concepts',
    hrefs: ['/concepts'],
    current: false,
    hideForTrialUsers: true,
    dataTestId: 'nav-concept-tab',
  },
  {
    name: 'Dynamic tags',
    hrefs: ['/dynamic-tag-categories'],
    current: false,
    hideForTrialUsers: true,
    hideForDynamicTagFF: true,
  },
  {
    name: 'Queries',
    hrefs: ['/queries'],
    current: false,
    hideForTrialUsers: true,
  },
  {
    name: 'Docs',
    hrefs: ['https://docs.coactive.ai'],
    current: false,
    external: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Page: React.FunctionComponent<PropsWithChildren> = function Page({
  children,
}) {
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const { user } = useAuth0();
  const { isTrialUser, expirationDate, auth0User } = useUserContext();
  const location = useLocation();
  const userContext = buildUserContext(auth0User?.sub);
  const isDynamicTaggingEnabled = evaluateFlag(
    FeatureFlag.ENABLE_DYNAMIC_TAGGING_V2,
    userContext,
  );

  const sidebarContextValue = useMemo(
    () => ({
      rightSidebarOpen,
      setRightSidebarOpen,
      leftSidebarOpen,
      setLeftSidebarOpen,
    }),
    [rightSidebarOpen, leftSidebarOpen],
  );

  if (location.pathname === '/login') {
    return (
      <div className="min-h-full h-full">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
        {children}
      </div>
    );
  }

  return (
    <div className="min-h-screen relative pt-16">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <SidebarAssetContextWrapper>
        <SidebarContext.Provider value={sidebarContextValue}>
          {ReactDOM.createPortal(
            <Disclosure
              as="nav"
              className="bg-white shadow-sm  z-30 fixed w-full"
            >
              {({ open }) => (
                <>
                  <div
                    className={classNames(
                      'mx-auto px-2 sm:px-4 transition-all duration-300',
                      Boolean(rightSidebarOpen || leftSidebarOpen) &&
                        'max-w-10xl sm:px-4',
                      Boolean(!rightSidebarOpen && !leftSidebarOpen) &&
                        'max-w-8xl sm:px-4 xxl:px-0',
                      Boolean(!leftSidebarOpen) && 'lg:px-8',
                    )}
                  >
                    <div className="flex justify-between h-16">
                      <div className="-mr-2 flex items-center sm:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                      <div className="flex">
                        {isTrialUser ? (
                          <div className="flex-shrink-0 flex items-center">
                            <Link
                              to="/"
                              className="flex justify-between items-center"
                            >
                              <img
                                src={CoactiveLogo}
                                alt="Home"
                                className="h-5 pr-2.5"
                              />
                              <div className="flex pr-6">
                                <span className="rounded-md border-1 border-green-400 bg-green-100 text-green-600 px-2 py-1 text-xs font-medium">
                                  Alpha
                                </span>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <div className="flex-shrink-0 flex items-center">
                            <Link to="/" className="flex justify-between">
                              <img
                                src={CoactiveLogo}
                                alt="Home"
                                className="h-5 pr-4"
                              />
                            </Link>
                          </div>
                        )}
                        <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                          {navigation
                            .filter(
                              (item) =>
                                (!item.hideForTrialUsers || !isTrialUser) &&
                                (!item.hideForDynamicTagFF ||
                                  isDynamicTaggingEnabled),
                            )
                            .map((item) => {
                              const inactiveClassnames =
                                'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300';
                              const otherClassnames =
                                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium';
                              return item.external ? (
                                <a
                                  key={item.name}
                                  href={item.hrefs[0]}
                                  className={`${inactiveClassnames} ${otherClassnames}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  data-cy={item.dataTestId}
                                >
                                  {item.name}
                                </a>
                              ) : (
                                <NavLink
                                  key={item.name}
                                  to={item.hrefs[0]}
                                  className={({ isActive }) =>
                                    classNames(
                                      isActive ||
                                        item.hrefs.includes(location.pathname)
                                        ? 'border-blue-600 text-gray-900'
                                        : inactiveClassnames,
                                      otherClassnames,
                                    )
                                  }
                                >
                                  <span data-cy={item.dataTestId}>
                                    {item.name}
                                  </span>
                                </NavLink>
                              );
                            })}
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <ProfileMenu
                          imgUrl={user?.picture}
                          firstName={user?.given_name}
                          lastName={user?.family_name}
                        />
                      </div>
                      {/* <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <button
                      type="button"
                      className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div> */}
                    </div>
                  </div>

                  <Disclosure.Panel className="sm:hidden">
                    <div className="pt-2 pb-3 space-y-1">
                      {navigation
                        .filter(
                          (item) => !item.hideForTrialUsers || !isTrialUser,
                        )
                        .map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.hrefs[0]}
                            className={classNames(
                              item.current
                                ? 'bg-blue-50 border-blue-600 text-blue-800'
                                : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                              'block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                    </div>
                    {/* <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-sm font-medium text-gray-800">
                        {user.name}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {user.email}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div> */}
                    {/* <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div> */}
                  </Disclosure.Panel>
                  {isTrialUser && <Banner expirationDate={expirationDate!} />}
                </>
              )}
            </Disclosure>,
            document.getElementById('c-nav')!,
          )}
          {children}
        </SidebarContext.Provider>
      </SidebarAssetContextWrapper>
    </div>
  );
};

export default Page;
