import { Column, DynamicTagCategoryTableRow } from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import { PaginationHook } from 'hooks/usePagination';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PaginationState } from 'types/pagination';

interface CategoryTableViewProps {
  columns: Column[];
  categories: DynamicTagCategoryTableRow[];
  pagination: PaginationHook;
  paginationState: PaginationState;
  isLoading: boolean;
  total: number;
}

export const CategoryTableView: React.FunctionComponent<CategoryTableViewProps> =
  function CategoryTableView({
    columns,
    categories,
    pagination,
    paginationState,
    isLoading,
    total,
  }) {
    const loadingTable = useMemo(() => <LoadingTable />, []);

    return (
      <>
        <FadeTransition show={Boolean(!isLoading && categories)} appear>
          <Table
            columns={columns}
            data={categories as any}
            idKey="categoryId"
            actions={[]}
            total={total}
            emptyMessage={
              <div className="text-center py-12 px-2 text-sm">
                <EmptyStateMessage>
                  <p className="pb-4">No categories to show</p>
                </EmptyStateMessage>
                <Link
                  to="/dynamic-tag-categories/add"
                  className="text-blue-500 hover:text-blue-600 font-medium"
                >
                  Create a category
                </Link>
              </div>
            }
            loadStrategy="paginated"
            activePage={paginationState.page}
            pageSize={paginationState.size}
            setPageSize={pagination.setSize}
            loadPage={pagination.setPage}
          />
        </FadeTransition>
        <FadeTransition
          show={Boolean(isLoading)}
          appear
          enterDelayMs={500}
          exit={false}
        >
          {!!isLoading && loadingTable}
        </FadeTransition>
      </>
    );
  };
