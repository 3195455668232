/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCategoryRequest,
  CreateCategoryResponse,
  CreateDynamicTagRequest,
  DynamicTagCategoryTableResponse,
  DynamicTagTableResponse,
  DynamicTagUI,
  ErrorResponse,
  FullCategoryResponseUI,
  GetDynamicTagPromptsRequest,
  GetDynamicTagPromptsResponse,
  HTTPValidationError,
  UpdateDynamicTagPromptsRequest,
  UpdateDynamicTagPromptsResponse,
  ValidateCategoryNameResponseUI,
  ValidateDynamicTagNameResponseUI,
  ValidateNameRequest,
} from '../models';
import {
    CreateCategoryRequestFromJSON,
    CreateCategoryRequestToJSON,
    CreateCategoryResponseFromJSON,
    CreateCategoryResponseToJSON,
    CreateDynamicTagRequestFromJSON,
    CreateDynamicTagRequestToJSON,
    DynamicTagCategoryTableResponseFromJSON,
    DynamicTagCategoryTableResponseToJSON,
    DynamicTagTableResponseFromJSON,
    DynamicTagTableResponseToJSON,
    DynamicTagUIFromJSON,
    DynamicTagUIToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FullCategoryResponseUIFromJSON,
    FullCategoryResponseUIToJSON,
    GetDynamicTagPromptsRequestFromJSON,
    GetDynamicTagPromptsRequestToJSON,
    GetDynamicTagPromptsResponseFromJSON,
    GetDynamicTagPromptsResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    UpdateDynamicTagPromptsRequestFromJSON,
    UpdateDynamicTagPromptsRequestToJSON,
    UpdateDynamicTagPromptsResponseFromJSON,
    UpdateDynamicTagPromptsResponseToJSON,
    ValidateCategoryNameResponseUIFromJSON,
    ValidateCategoryNameResponseUIToJSON,
    ValidateDynamicTagNameResponseUIFromJSON,
    ValidateDynamicTagNameResponseUIToJSON,
    ValidateNameRequestFromJSON,
    ValidateNameRequestToJSON,
} from '../models';

export interface CreateCategoryOperationRequest {
    createCategoryRequest: CreateCategoryRequest;
}

export interface CreateDynamicTagTableNewRequest {
    categoryId: string;
    createDynamicTagRequest: CreateDynamicTagRequest;
}

export interface GetCategoriesAsTableRequest {
    offset?: number | null;
    limit?: number | null;
}

export interface GetCategoryRequest {
    categoryId: string;
}

export interface GetDynamicTagRequest {
    categoryId: string;
    dynamicTagId: string;
}

export interface GetDynamicTagsAsTableRequest {
    categoryId: string;
    offset?: number | null;
    limit?: number | null;
}

export interface GetDynamicTagsPromptsRequest {
    categoryId: string;
    getDynamicTagPromptsRequest: GetDynamicTagPromptsRequest;
}

export interface UpdateDynamicTagPromptsOperationRequest {
    dynamicTagId: string;
    categoryId: string;
    updateDynamicTagPromptsRequest: UpdateDynamicTagPromptsRequest;
}

export interface ValidateCategoryNameRequest {
    validateNameRequest: ValidateNameRequest;
}

export interface ValidateDynamicTagNameRequest {
    categoryId: string;
    validateNameRequest: ValidateNameRequest;
}

/**
 * DynamicTagsApi - interface
 * 
 * @export
 * @interface DynamicTagsApiInterface
 */
export interface DynamicTagsApiInterface {
    /**
     * Create a new category
     * @summary Create a new category
     * @param {CreateCategoryRequest} createCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    createCategoryRaw(requestParameters: CreateCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCategoryResponse>>;

    /**
     * Create a new category
     * Create a new category
     */
    createCategory(requestParameters: CreateCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCategoryResponse>;

    /**
     * Create a dynamic tag table that records scores for each tag for each asset
     * @summary Create dynamic tag table for dataset
     * @param {string} categoryId The unique identifier for the category
     * @param {CreateDynamicTagRequest} createDynamicTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    createDynamicTagTableNewRaw(requestParameters: CreateDynamicTagTableNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Create a dynamic tag table that records scores for each tag for each asset
     * Create dynamic tag table for dataset
     */
    createDynamicTagTableNew(requestParameters: CreateDynamicTagTableNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Get all categories paginated in table format
     * @summary Get all categories in table format
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    getCategoriesAsTableRaw(requestParameters: GetCategoriesAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTagCategoryTableResponse>>;

    /**
     * Get all categories paginated in table format
     * Get all categories in table format
     */
    getCategoriesAsTable(requestParameters: GetCategoriesAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTagCategoryTableResponse>;

    /**
     * Get category by id
     * @summary Get category by id
     * @param {string} categoryId The unique identifier for the category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    getCategoryRaw(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullCategoryResponseUI>>;

    /**
     * Get category by id
     * Get category by id
     */
    getCategory(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullCategoryResponseUI>;

    /**
     * Get information about a specific dynamic tag using the id
     * @summary Get dynamic tag by id
     * @param {string} categoryId The unique identifier for the category
     * @param {string} dynamicTagId The unique identifier for the dynamic tag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    getDynamicTagRaw(requestParameters: GetDynamicTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTagUI>>;

    /**
     * Get information about a specific dynamic tag using the id
     * Get dynamic tag by id
     */
    getDynamicTag(requestParameters: GetDynamicTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTagUI>;

    /**
     * Get all dynamic tags paginated in table format
     * @summary Get all dynamic tags in table format
     * @param {string} categoryId The unique identifier for the category
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    getDynamicTagsAsTableRaw(requestParameters: GetDynamicTagsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTagTableResponse>>;

    /**
     * Get all dynamic tags paginated in table format
     * Get all dynamic tags in table format
     */
    getDynamicTagsAsTable(requestParameters: GetDynamicTagsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTagTableResponse>;

    /**
     * Get all text and visual prompts for a dynamic tag
     * @summary Get all text and visual prompts for a dynamic tag
     * @param {string} categoryId The unique identifier for the category
     * @param {GetDynamicTagPromptsRequest} getDynamicTagPromptsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    getDynamicTagsPromptsRaw(requestParameters: GetDynamicTagsPromptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDynamicTagPromptsResponse>>;

    /**
     * Get all text and visual prompts for a dynamic tag
     * Get all text and visual prompts for a dynamic tag
     */
    getDynamicTagsPrompts(requestParameters: GetDynamicTagsPromptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDynamicTagPromptsResponse>;

    /**
     * Update text and visual prompts for a dynamic tag
     * @summary Update text and visual prompts for a dynamic tag
     * @param {string} dynamicTagId The unique identifier for the dynamic tag
     * @param {string} categoryId The unique identifier for the category
     * @param {UpdateDynamicTagPromptsRequest} updateDynamicTagPromptsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    updateDynamicTagPromptsRaw(requestParameters: UpdateDynamicTagPromptsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDynamicTagPromptsResponse>>;

    /**
     * Update text and visual prompts for a dynamic tag
     * Update text and visual prompts for a dynamic tag
     */
    updateDynamicTagPrompts(requestParameters: UpdateDynamicTagPromptsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDynamicTagPromptsResponse>;

    /**
     * Check if a category name is valid
     * @summary Validate Category Name
     * @param {ValidateNameRequest} validateNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    validateCategoryNameRaw(requestParameters: ValidateCategoryNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateCategoryNameResponseUI>>;

    /**
     * Check if a category name is valid
     * Validate Category Name
     */
    validateCategoryName(requestParameters: ValidateCategoryNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateCategoryNameResponseUI>;

    /**
     * Check if a dynamic tag name is valid
     * @summary Validate Dynamic Tag Name
     * @param {string} categoryId The unique identifier for the category
     * @param {ValidateNameRequest} validateNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicTagsApiInterface
     */
    validateDynamicTagNameRaw(requestParameters: ValidateDynamicTagNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateDynamicTagNameResponseUI>>;

    /**
     * Check if a dynamic tag name is valid
     * Validate Dynamic Tag Name
     */
    validateDynamicTagName(requestParameters: ValidateDynamicTagNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateDynamicTagNameResponseUI>;

}

/**
 * 
 */
export class DynamicTagsApi extends runtime.BaseAPI implements DynamicTagsApiInterface {

    /**
     * Create a new category
     * Create a new category
     */
    async createCategoryRaw(requestParameters: CreateCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCategoryResponse>> {
        if (requestParameters.createCategoryRequest === null || requestParameters.createCategoryRequest === undefined) {
            throw new runtime.RequiredError('createCategoryRequest','Required parameter requestParameters.createCategoryRequest was null or undefined when calling createCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCategoryRequestToJSON(requestParameters.createCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Create a new category
     * Create a new category
     */
    async createCategory(requestParameters: CreateCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCategoryResponse> {
        const response = await this.createCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a dynamic tag table that records scores for each tag for each asset
     * Create dynamic tag table for dataset
     */
    async createDynamicTagTableNewRaw(requestParameters: CreateDynamicTagTableNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling createDynamicTagTableNew.');
        }

        if (requestParameters.createDynamicTagRequest === null || requestParameters.createDynamicTagRequest === undefined) {
            throw new runtime.RequiredError('createDynamicTagRequest','Required parameter requestParameters.createDynamicTagRequest was null or undefined when calling createDynamicTagTableNew.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories/{category_id}/dynamic-tags`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDynamicTagRequestToJSON(requestParameters.createDynamicTagRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a dynamic tag table that records scores for each tag for each asset
     * Create dynamic tag table for dataset
     */
    async createDynamicTagTableNew(requestParameters: CreateDynamicTagTableNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createDynamicTagTableNewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all categories paginated in table format
     * Get all categories in table format
     */
    async getCategoriesAsTableRaw(requestParameters: GetCategoriesAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTagCategoryTableResponse>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicTagCategoryTableResponseFromJSON(jsonValue));
    }

    /**
     * Get all categories paginated in table format
     * Get all categories in table format
     */
    async getCategoriesAsTable(requestParameters: GetCategoriesAsTableRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTagCategoryTableResponse> {
        const response = await this.getCategoriesAsTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get category by id
     * Get category by id
     */
    async getCategoryRaw(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullCategoryResponseUI>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories/{category_id}`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullCategoryResponseUIFromJSON(jsonValue));
    }

    /**
     * Get category by id
     * Get category by id
     */
    async getCategory(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullCategoryResponseUI> {
        const response = await this.getCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get information about a specific dynamic tag using the id
     * Get dynamic tag by id
     */
    async getDynamicTagRaw(requestParameters: GetDynamicTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTagUI>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getDynamicTag.');
        }

        if (requestParameters.dynamicTagId === null || requestParameters.dynamicTagId === undefined) {
            throw new runtime.RequiredError('dynamicTagId','Required parameter requestParameters.dynamicTagId was null or undefined when calling getDynamicTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories/{category_id}/dynamic-tags/{dynamic_tag_id}`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"dynamic_tag_id"}}`, encodeURIComponent(String(requestParameters.dynamicTagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicTagUIFromJSON(jsonValue));
    }

    /**
     * Get information about a specific dynamic tag using the id
     * Get dynamic tag by id
     */
    async getDynamicTag(requestParameters: GetDynamicTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTagUI> {
        const response = await this.getDynamicTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all dynamic tags paginated in table format
     * Get all dynamic tags in table format
     */
    async getDynamicTagsAsTableRaw(requestParameters: GetDynamicTagsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicTagTableResponse>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getDynamicTagsAsTable.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories/{category_id}/dynamic-tags`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicTagTableResponseFromJSON(jsonValue));
    }

    /**
     * Get all dynamic tags paginated in table format
     * Get all dynamic tags in table format
     */
    async getDynamicTagsAsTable(requestParameters: GetDynamicTagsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicTagTableResponse> {
        const response = await this.getDynamicTagsAsTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all text and visual prompts for a dynamic tag
     * Get all text and visual prompts for a dynamic tag
     */
    async getDynamicTagsPromptsRaw(requestParameters: GetDynamicTagsPromptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDynamicTagPromptsResponse>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getDynamicTagsPrompts.');
        }

        if (requestParameters.getDynamicTagPromptsRequest === null || requestParameters.getDynamicTagPromptsRequest === undefined) {
            throw new runtime.RequiredError('getDynamicTagPromptsRequest','Required parameter requestParameters.getDynamicTagPromptsRequest was null or undefined when calling getDynamicTagsPrompts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories/{category_id}/dynamic-tags/prompts`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetDynamicTagPromptsRequestToJSON(requestParameters.getDynamicTagPromptsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDynamicTagPromptsResponseFromJSON(jsonValue));
    }

    /**
     * Get all text and visual prompts for a dynamic tag
     * Get all text and visual prompts for a dynamic tag
     */
    async getDynamicTagsPrompts(requestParameters: GetDynamicTagsPromptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDynamicTagPromptsResponse> {
        const response = await this.getDynamicTagsPromptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update text and visual prompts for a dynamic tag
     * Update text and visual prompts for a dynamic tag
     */
    async updateDynamicTagPromptsRaw(requestParameters: UpdateDynamicTagPromptsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDynamicTagPromptsResponse>> {
        if (requestParameters.dynamicTagId === null || requestParameters.dynamicTagId === undefined) {
            throw new runtime.RequiredError('dynamicTagId','Required parameter requestParameters.dynamicTagId was null or undefined when calling updateDynamicTagPrompts.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling updateDynamicTagPrompts.');
        }

        if (requestParameters.updateDynamicTagPromptsRequest === null || requestParameters.updateDynamicTagPromptsRequest === undefined) {
            throw new runtime.RequiredError('updateDynamicTagPromptsRequest','Required parameter requestParameters.updateDynamicTagPromptsRequest was null or undefined when calling updateDynamicTagPrompts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories/{category_id}/dynamic-tags/{dynamic_tag_id}/update-prompts`.replace(`{${"dynamic_tag_id"}}`, encodeURIComponent(String(requestParameters.dynamicTagId))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDynamicTagPromptsRequestToJSON(requestParameters.updateDynamicTagPromptsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDynamicTagPromptsResponseFromJSON(jsonValue));
    }

    /**
     * Update text and visual prompts for a dynamic tag
     * Update text and visual prompts for a dynamic tag
     */
    async updateDynamicTagPrompts(requestParameters: UpdateDynamicTagPromptsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDynamicTagPromptsResponse> {
        const response = await this.updateDynamicTagPromptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if a category name is valid
     * Validate Category Name
     */
    async validateCategoryNameRaw(requestParameters: ValidateCategoryNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateCategoryNameResponseUI>> {
        if (requestParameters.validateNameRequest === null || requestParameters.validateNameRequest === undefined) {
            throw new runtime.RequiredError('validateNameRequest','Required parameter requestParameters.validateNameRequest was null or undefined when calling validateCategoryName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories/validate/name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateNameRequestToJSON(requestParameters.validateNameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateCategoryNameResponseUIFromJSON(jsonValue));
    }

    /**
     * Check if a category name is valid
     * Validate Category Name
     */
    async validateCategoryName(requestParameters: ValidateCategoryNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateCategoryNameResponseUI> {
        const response = await this.validateCategoryNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if a dynamic tag name is valid
     * Validate Dynamic Tag Name
     */
    async validateDynamicTagNameRaw(requestParameters: ValidateDynamicTagNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateDynamicTagNameResponseUI>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling validateDynamicTagName.');
        }

        if (requestParameters.validateNameRequest === null || requestParameters.validateNameRequest === undefined) {
            throw new runtime.RequiredError('validateNameRequest','Required parameter requestParameters.validateNameRequest was null or undefined when calling validateDynamicTagName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dynamic-tag-categories/{category_id}/validate/name`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateNameRequestToJSON(requestParameters.validateNameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateDynamicTagNameResponseUIFromJSON(jsonValue));
    }

    /**
     * Check if a dynamic tag name is valid
     * Validate Dynamic Tag Name
     */
    async validateDynamicTagName(requestParameters: ValidateDynamicTagNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateDynamicTagNameResponseUI> {
        const response = await this.validateDynamicTagNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
