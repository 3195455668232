import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { AssetResponse } from 'api/generated';
import Button from 'components/Button';
import DatasetSearch from 'components/DatasetSearch';
import IconButton from 'components/IconButton';
import React, { Fragment, useRef, useState } from 'react';

interface AddVisualPromptsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  visualPrompts: AssetResponse[];
  setVisualPrompts: (visualPrompts: AssetResponse[]) => void;
  setIsEditingPrompts: (isEditingPrompts: boolean) => void;
  datasetId: string;
}

const AddVisualPrompts: React.FC<AddVisualPromptsProps> = ({
  open,
  setOpen,
  setVisualPrompts,
  visualPrompts,
  setIsEditingPrompts,
  datasetId,
}) => {
  const [selectedAssets, setSelectedAssets] = useState<AssetResponse[]>([]);
  const cancelButtonRef = useRef(null);

  const handleSelectAssets = (asset: AssetResponse) => {
    setIsEditingPrompts(true);
    if (selectedAssets.includes(asset)) {
      setSelectedAssets(
        selectedAssets.filter(
          (selectedAsset) =>
            selectedAsset.coactiveImageId !== asset.coactiveImageId,
        ),
      );
    } else {
      setSelectedAssets([...selectedAssets, asset]);
    }
  };
  const handleCloseModal = () => {
    setOpen(false);
    setSelectedAssets([]);
  };

  const handleAddSelectedAssets = () => {
    const deduplicateSet = new Set([...visualPrompts, ...selectedAssets]);
    setVisualPrompts([...deduplicateSet]);
    handleCloseModal();
  };

  return (
    <Transition.Root show={open} as="div" className="absolute">
      <HeadlessDialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={handleCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex w-full h-full items-end justify-center p-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HeadlessDialog.Panel className="px-6 py-4 flex flex-col relative h-full w-3/4 rounded-lg bg-white overflow-hidden">
                <div className="w-full justify-between flex">
                  <h2 className="font-bold text-lg text-gray-800">
                    Add visual prompts
                  </h2>
                  <IconButton
                    Icon={XMarkIcon}
                    onClick={handleCloseModal}
                    rounded="full"
                  />
                </div>
                <div className="overflow-y-auto px-3 h-[calc(100%-4rem)]">
                  <DatasetSearch
                    datasetId={datasetId || ''}
                    onClick={(img) => handleSelectAssets(img)}
                    hideHeader
                    searchMode="visual"
                    defaultAssetType="All content"
                    searchContainerClassName="w-full mx-auto my-6"
                    hasToggleGridResults
                    disabledResults={visualPrompts}
                    disabledResultMessage="Existing visual prompt"
                    searchModeOptions={[
                      {
                        label: 'Visual search',
                        value: 'visual',
                      },
                    ]}
                  />
                </div>
                <div className="absolute  bg-white left-0 w-full border-t border-gray-200 bottom-0 flex justify-between items-center px-6 py-4">
                  <p className="text-sm text-gray-800 font-medium">
                    {selectedAssets.length ? selectedAssets.length : 'No'}{' '}
                    visual prompts selected
                  </p>

                  <Button
                    disabled={selectedAssets.length === 0}
                    buttonStyle="primary"
                    onClick={handleAddSelectedAssets}
                  >
                    Add visual prompts
                  </Button>
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
};

export default AddVisualPrompts;
