import { XMarkIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button';
import PrefixListInput from 'components/PrefixListInputInput';
import React, { KeyboardEvent, useState } from 'react';

interface TextPromptsProps {
  prompts: string[];
  setPrompts: (prompts: string[]) => void;
  setIsEditingPrompts: (isEditingPrompts: boolean) => void;
  onPromptClick?: any;
}
const TextPrompts: React.FC<TextPromptsProps> = ({
  prompts,
  setPrompts,
  setIsEditingPrompts,
  onPromptClick,
}: TextPromptsProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const handleRemovePrompt = (idx: number) => {
    setIsEditingPrompts(true);
    setPrompts(prompts.filter((_, subIdx) => idx !== subIdx));
    onPromptClick?.(idx);
  };
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = e.currentTarget.value.trim();
    const isInputValueValid =
      trimmedValue.length && !prompts.includes(trimmedValue);

    if (e.key === 'Enter' && isInputValueValid) {
      setPrompts([...prompts, trimmedValue]);
      setInputValue('');
      setIsEditingPrompts(true);
    }
  };

  return (
    <PrefixListInput
      placeholder="Start typing to add a text prompt..."
      label="Text prompts"
      value={inputValue}
      onChange={setInputValue}
      prefixList={prompts.map((prompt: any, key: number) => (
        <Button
          key={prompt}
          onClick={() => handleRemovePrompt(key)}
          type="button"
          buttonStyle="soft"
          rightIcon={XMarkIcon}
          size="small"
          textColor="gray-600"
        >
          {prompt}
        </Button>
      ))}
      showPrefixCount
      onKeyDown={onKeyDown}
    />
  );
};

TextPrompts.defaultProps = {
  onPromptClick: undefined,
};

export default TextPrompts;
