import { Column, ColumnDataType, ColumnDisplayType } from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import { PaginationHook } from 'hooks/usePagination';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PaginationState } from 'types/pagination';

interface DynamicTagTableViewProps {
  columns: Column[];
  // TODO: fix typing
  dynamicTags: any[];
  pagination: PaginationHook;
  paginationState: PaginationState;
  isLoading: boolean;
  total: number;
}

const COLUMNS = [
  {
    key: 'name',
    label: 'Dynamic tags',
    to: '/dynamic-tag-categories/{categoryId}/dynamic-tag/{dynamicTagId}',
    displayType: ColumnDisplayType.Action,
  },
  {
    key: 'textPrompts',
    label: 'Text prompts',
    displayType: ColumnDisplayType.BadgeList,
  },
  {
    key: 'visualPrompts',
    label: 'Visual prompts',
    displayType: ColumnDisplayType.ImageSetPreview,
  },
  {
    key: 'updatedDt',
    label: 'Last updated',
    displayType: ColumnDisplayType.RelativeTimestamp,
    dataType: ColumnDataType.Date,
  },
];

export const DynamicTagTableView: React.FunctionComponent<DynamicTagTableViewProps> =
  function DynamicTagTableView({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    columns,
    dynamicTags,
    pagination,
    paginationState,
    isLoading,
    total,
  }) {
    const loadingTable = useMemo(() => <LoadingTable />, []);

    return (
      <>
        <FadeTransition show={Boolean(!isLoading && dynamicTags)} appear>
          <Table
            columns={COLUMNS}
            data={dynamicTags as any}
            idKey="dynamicTagId"
            actions={[]}
            total={total}
            emptyMessage={
              <div className="text-center py-12 px-2 text-sm">
                <EmptyStateMessage>
                  <p className="pb-4">No dynamic tags to show</p>
                </EmptyStateMessage>
                <Link
                  to="dynamic-tag/add"
                  className="text-blue-500 hover:text-blue-600 font-medium"
                >
                  Create a dynamic tag
                </Link>
              </div>
            }
            loadStrategy="paginated"
            activePage={paginationState.page}
            pageSize={paginationState.size}
            setPageSize={pagination.setSize}
            loadPage={pagination.setPage}
          />
        </FadeTransition>
        <FadeTransition
          show={Boolean(isLoading)}
          appear
          enterDelayMs={500}
          exit={false}
        >
          {!!isLoading && loadingTable}
        </FadeTransition>
      </>
    );
  };
