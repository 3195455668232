import { Column, DatasetStatusEnum, DatasetTableRow } from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import { getDatasetsQueryKey as getConceptDatasetsQueryKey } from 'pages/concepts/queries';
import {
  invalidateDatasetQueries,
  useDeleteDatasetMutation,
} from 'queries/datasets';
import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaginationState } from 'types/pagination';
import { TablePageSize } from 'types/table';

interface TableViewProps {
  columns: Column[];
  datasets: DatasetTableRow[];
  total: number;
  refetchDatasets: () => void;
  loading?: boolean;
  pagination: PaginationState;
  setPageSize: (size: TablePageSize) => void;
  loadPage: (page: number) => void;
}

const TableView: React.FunctionComponent<TableViewProps> = function TableView({
  columns,
  datasets,
  total,
  refetchDatasets,
  loading,
  pagination,
  setPageSize,
  loadPage,
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteDataset } = useDeleteDatasetMutation();
  const loadingTable = useMemo(() => <LoadingTable />, []);

  const getStatusClassName = (status: string) => {
    switch (status) {
      case DatasetStatusEnum.Waiting:
        return 'text-yellow-500';
      case DatasetStatusEnum.Initializing:
        return 'text-blue-500 animate-pulse';
      case DatasetStatusEnum.Ready:
        return 'text-green-600';
      case DatasetStatusEnum.Error:
        return 'text-red-500';
      default:
        return 'text-slate-400';
    }
  };

  return (
    <>
      <FadeTransition show={Boolean(!loading && datasets)} appear>
        <div className="mt-2">
          <Table
            columns={columns}
            data={datasets as any}
            idKey="datasetId"
            actions={[
              {
                key: 'create',
                label: 'Create Concept',
                dynamicHide: (row: DatasetTableRow) => !row.data.isDeletable,
                action: (row: DatasetTableRow) =>
                  navigate({
                    pathname: '/concepts/create',
                    search: `?datasetId=${row.data.datasetId}`,
                  }),
              },
              {
                key: 'delete',
                label: 'Delete Dataset',
                danger: true,
                dataTestId: 'delete-dataset',
                dynamicHide: (row: DatasetTableRow) => !row.data.isDeletable,
                action: (row: DatasetTableRow) => {
                  deleteDataset(
                    { datasetId: row.data.datasetId },
                    {
                      onSuccess: () => {
                        refetchDatasets();
                      },
                      onError: () => {
                        toast.error(
                          `Unable to delete dataset ${row.data.name}. Try Again.`,
                        );
                      },
                    },
                  );
                  invalidateDatasetQueries(queryClient);
                  queryClient.invalidateQueries(getConceptDatasetsQueryKey());
                },
              },
            ]}
            total={total}
            emptyMessage={
              <div className="text-center py-12 px-2 text-sm">
                <EmptyStateMessage>
                  <p className="pb-4">No datasets to show</p>
                </EmptyStateMessage>
                <Link
                  to="/datasets/create"
                  className="text-blue-500 hover:text-blue-600 font-medium"
                >
                  Create a Dataset
                </Link>
              </div>
            }
            getStatusClassName={getStatusClassName}
            loadStrategy="paginated"
            activePage={pagination.page}
            pageSize={pagination.size}
            setPageSize={setPageSize}
            loadPage={loadPage}
          />
        </div>
      </FadeTransition>
      <FadeTransition
        show={Boolean(loading)}
        appear
        enterDelayMs={500}
        exit={false}
      >
        {!!loading && loadingTable}
      </FadeTransition>
    </>
  );
};

TableView.defaultProps = {
  loading: false,
};
export default TableView;
