import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { findByIds } from 'utils/ListUtils';

export interface DropdownOptionIcon {
  component: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>> | string;
  alt?: string;
  fill?: string;
}

export interface DropdownOption<T extends string> {
  label: string;
  value: T;
  icon?: DropdownOptionIcon;
  disabled?: boolean;
  active?: boolean;
}

interface DropdownProps<T extends string, V extends T | T[]> {
  options: DropdownOption<T>[];
  border?: string;
  buttonShadow?: string;
  buttonStyle?: string;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  dropdownStyle?: 'default' | 'compressed' | 'inline';
  focus?: string;
  label?: string;
  menuPosition?: 'top' | 'bottom' | 'right' | 'left';
  onChange?: (value: T) => void;
  onChangeMulti?: (value: T[]) => void;
  size?: 'small';
  multiple?: boolean;
  optionsLoading?: boolean;
  placeholder?: string;
  rounded?: string;
  selected?: V;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Dropdown = function Dropdown<T extends string, V extends T | T[]>({
  border,
  buttonShadow,
  buttonStyle,
  className,
  dataTestId,
  disabled: disabledProp,
  dropdownStyle,
  focus,
  label,
  menuPosition,
  multiple,
  onChange: onChangeHandler,
  onChangeMulti: onChangeMultiHandler,
  options,
  optionsLoading,
  placeholder,
  rounded,
  selected: selectedProp,
  size,
}: DropdownProps<T, V>) {
  const [selected, setSelected] = useState<T[]>(
    ((typeof selectedProp === 'string'
      ? [selectedProp]
      : selectedProp) as T[]) ?? [],
  );

  const onChange = useCallback(
    (value: T | T[]) => {
      const valueArr = typeof value === 'string' ? [value] : value;
      setSelected(valueArr);
      if (multiple) {
        onChangeMultiHandler?.(value as T[]);
      } else {
        onChangeHandler?.(value as T);
      }
    },
    [multiple, onChangeMultiHandler, onChangeHandler],
  );

  useEffect(() => {
    setSelected(
      (typeof selectedProp === 'string'
        ? ([selectedProp] as T[])
        : (selectedProp as T[])) ?? [],
    );
  }, [selectedProp, multiple]);

  const selectedOptions: DropdownOption<T>[] = findByIds(options, selected);
  const singleSelectedOption = selectedOptions[0];
  const singleSelectedOptionNotFound = Boolean(
    !options.length && !selectedOptions.length && selected[0],
  );
  const SingleSelectedOptionIconComponent =
    singleSelectedOption?.icon?.component;

  return (
    <Listbox
      value={selected}
      onChange={onChange}
      disabled={disabledProp || !options.length}
      multiple={multiple}
    >
      {({ open, disabled }) => (
        <>
          {!!label && (
            <Listbox.Label
              className={classNames(
                'text-sm font-medium text-gray-700',
                dropdownStyle === 'inline' ? 'block' : '',
              )}
            >
              {label}
            </Listbox.Label>
          )}
          <div
            className={classNames(
              'relative',
              className,
              !!label && 'mt-1',
              dropdownStyle === 'inline' ? 'inline-block' : 'block',
            )}
          >
            <Listbox.Button
              className={classNames(
                'shadow-sm font-medium hover:bg-slate-50 cursor-pointer text-sm relative bg-white border border-gray-300 pr-8 text-left cursor-default  sm:text-sm',
                Boolean(disabled) &&
                  'disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none',
                border || 'border border-gray-300',
                dropdownStyle === 'inline' && 'inline',
                dropdownStyle !== 'inline' && 'w-full',
                buttonShadow,
                buttonStyle,
                focus ||
                  'focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-600',
                multiple && selectedOptions.length ? 'pl-1 py-1' : 'pl-3 py-1',
                rounded || 'rounded-md',
              )}
            >
              <div data-cy={dataTestId} className="flex items-center space-x-1">
                {!multiple &&
                  (selectedOptions[0] ? (
                    // Multiple selected options (multiple = false)

                    <>
                      {selectedOptions[0] &&
                        SingleSelectedOptionIconComponent && (
                          <SingleSelectedOptionIconComponent
                            aria-label={singleSelectedOption?.icon?.alt}
                            className={classNames(
                              'flex-shrink-0 h-6 w-6 rounded-full',
                              singleSelectedOption?.icon?.fill ??
                                'fill-slate-400',
                            )}
                          />
                        )}
                      <span
                        title={selectedOptions[0].label}
                        className={classNames(
                          Boolean(singleSelectedOption?.icon?.component) &&
                            'ml-2',
                          'block truncate',
                        )}
                      >
                        {singleSelectedOption?.label}
                      </span>
                    </>
                  ) : (
                    <span className="text-gray-500">
                      {optionsLoading && <>&nbsp;</>}
                      {!optionsLoading && singleSelectedOptionNotFound
                        ? selected
                        : null}
                      {!optionsLoading && !singleSelectedOptionNotFound
                        ? placeholder ?? 'Select One'
                        : null}
                    </span>
                  ))}

                {multiple &&
                  (selectedOptions.length ? (
                    // Multiple selected options (multiple = true)
                    selectedOptions.map((selectedOption) => (
                      <div
                        className="inline-flex items-center justify-center bg-gray-200 py-1 px-2 rounded-md"
                        key={selectedOption.value}
                      >
                        {selectedOption.icon?.component && (
                          <selectedOption.icon.component
                            aria-label={selectedOption.icon.alt}
                            className={classNames(
                              'flex-shrink-0 h-6 w-6 rounded-full',
                              selectedOption.icon.fill ?? 'fill-slate-400',
                            )}
                          />
                        )}
                        <span
                          title={selectedOption.label}
                          className={classNames(
                            Boolean(selectedOption.icon?.component) && 'ml-2',
                            'block truncate',
                          )}
                        >
                          {selectedOption.label}
                        </span>
                      </div>
                    ))
                  ) : (
                    <span className="text-slate-700">
                      {optionsLoading && ' '}
                      {!optionsLoading ? placeholder ?? 'Select Many' : null}
                    </span>
                  ))}
              </div>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className={classNames(
                    'text-slate-700',
                    size === 'small' ? 'h-4 w-4' : 'h-5 w-5',
                  )}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  menuPosition === 'top' ? 'bottom-12' : '',
                  'absolute min-w-full mt-1 z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                )}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    title={option.label}
                    disabled={option.disabled}
                    className={({ active }) =>
                      classNames(
                        'cursor-default select-none relative py-2 pl-3 group',
                        option.disabled ? 'text-slate-700' : '',
                        active ? 'bg-slate-100' : '',
                        !active && !option.disabled ? 'text-gray-900' : '',
                        dropdownStyle === 'compressed' ? 'pr-3' : 'pr-9',
                      )
                    }
                    value={option.value}
                  >
                    {({ selected: optionSelected, active }) => (
                      <>
                        <div
                          className="flex items-center"
                          data-cy={`${dataTestId}-option`}
                        >
                          {option.icon?.component && (
                            <option.icon.component
                              aria-label={option.icon.alt}
                              className={classNames(
                                'flex-shrink-0 h-6 w-6 rounded-full',
                                option.icon.fill ?? 'fill-slate-400',
                              )}
                            />
                          )}
                          <span
                            className={classNames(
                              'ml-2 block truncate',
                              optionSelected ? 'font-semibold' : 'font-normal',
                              dropdownStyle === 'compressed' &&
                                optionSelected &&
                                !active
                                ? 'text-blue-500'
                                : '',
                              dropdownStyle === 'compressed' &&
                                optionSelected &&
                                active
                                ? 'text-white'
                                : '',
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {(optionSelected && dropdownStyle !== 'compressed') ||
                        option.active ? (
                          <span
                            className={classNames(
                              active ? 'text-slate-600' : 'text-slate-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon
                              className="h-5 w-5 pl-2"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

Dropdown.defaultProps = {
  border: undefined,
  buttonShadow: 'shadow-sm',
  buttonStyle: undefined,
  className: undefined,
  dataTestId: undefined,
  disabled: false,
  dropdownStyle: 'default',
  focus: undefined,
  label: undefined,
  menuPosition: 'bottom',
  multiple: false,
  onChange: undefined,
  onChangeMulti: undefined,
  optionsLoading: false,
  placeholder: undefined,
  rounded: undefined,
  selected: undefined,
  size: undefined,
};

export default Dropdown;
