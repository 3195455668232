import Button from 'components/Button';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Main from 'components/Main';
import PageHeader from 'components/PageHeader';
import usePagination from 'hooks/usePagination';
import { getCategoriesAsTableQuery } from 'queries/dynamic-tags';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaginationState } from 'types/pagination';
import { EmptyErrorState } from '../components/ErrorEmptyState';
import { CategoryTableView } from './components/CategoryTableView';

const DynamicTagAllCategoriesPage = function DynamicTagAllCategoriesPage() {
  const pagination = usePagination();
  const navigate = useNavigate();
  const {
    data: categories,
    isLoading,
    isFetched,
    isError,
  } = getCategoriesAsTableQuery({
    limit: pagination.size,
    offset: (pagination.page - 1) * pagination.size,
  });
  const paginationState = useMemo<PaginationState>(
    () => ({ page: pagination.page, size: pagination.size }),
    [pagination.page, pagination.size],
  );
  const loadingTable = useMemo(() => <LoadingTable />, []);
  const loading = isLoading && !isFetched;

  // TODO: update to support multiple datasets
  // modify the structure of the dynamic tags for rendering purposes
  const parsedResponseData = categories
    ? categories.data.map((tableRow) => ({
        ...tableRow,
        data: {
          ...tableRow.data,
          dynamicTags: Object.values(tableRow.data.dynamicTagsById || {}).join(
            ', ',
          ),
          datasetName: tableRow.data.datasets[0].name,
          datasetId: tableRow.data.datasets[0].id,
        },
      }))
    : [];

  return (
    <Main>
      <div className="max-w-8xl mx-auto">
        <PageHeader
          title={<h1>Dynamic tag categories</h1>}
          backButtonTo=""
          actions={
            <Button onClick={() => navigate('add')}>Add category</Button>
          }
        />
        {isError ? (
          <div className="h-[80vh] flex items-center justify-center">
            <EmptyErrorState itemWithError="categories" />
          </div>
        ) : (
          <div className="pt-8">
            <FadeTransition show={Boolean(!loading && categories)} appear>
              {categories && (
                <CategoryTableView
                  categories={parsedResponseData}
                  pagination={pagination}
                  total={categories.meta.page.total || 0}
                  columns={categories.columns}
                  isLoading={isLoading}
                  paginationState={paginationState}
                />
              )}
            </FadeTransition>
            <FadeTransition
              show={loading}
              appear
              enterDelayMs={500}
              exit={false}
            >
              {loading && loadingTable}
            </FadeTransition>
          </div>
        )}
      </div>
    </Main>
  );
};

export default DynamicTagAllCategoriesPage;
