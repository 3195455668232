import { Popover } from '@headlessui/react';
import { VideoCameraIcon } from '@heroicons/react/24/solid';
import { AssetResponse, AssetType } from 'api/generated';
import classNames from 'classnames';
import ProtectedImage from 'components/Image/ProtectedImage';
import { LOADING_BLOCK_STYLE } from 'components/LoadingBlock';
import isEqual from 'lodash.isequal';
import React, { JSX } from 'react';
import './index.scss';
import { UnifiedAssetResponse, LabeledAssetResponse } from 'types/image';
import SelectedIcon from 'components/ImageGrid/SelectedIcon';
import Label from './Label';

interface TileProps<T> {
  image: LabeledAssetResponse | undefined;
  data?: T;
  selectable: boolean | undefined;
  selected: boolean;
  onClick: ((image: AssetResponse) => void) | undefined;
  effects: string[] | undefined;
  showMenu: boolean;
  popoverComponent?: React.FC<{ image: AssetResponse }>;
  popoverClassName?: string;
  assetType?: AssetType;
  detailComponent?: React.FC<{ data: T | undefined }>;
  selectStyle?: 'numbered' | 'default';
  order?: number;
  icon?: JSX.Element;
  disabledMessage?: string;
  tileButton?: (img: AssetResponse) => JSX.Element;
  footer?: (img: UnifiedAssetResponse) => JSX.Element;
}

function propsAreEqual<T>(
  prevProps: TileProps<T>,
  nextProps: TileProps<T>,
): boolean {
  return isEqual(prevProps, nextProps);
}

const Tile: React.FunctionComponent<TileProps<any>> = React.memo(
  <T extends any>({
    effects,
    image,
    onClick,
    showMenu,
    selectable,
    selected,
    popoverComponent,
    popoverClassName,
    assetType,
    detailComponent,
    data,
    selectStyle,
    order,
    icon,
    footer,
    disabledMessage,
    tileButton,
  }: TileProps<T>) => {
    const PopoverComponent = popoverComponent;
    const DetailComponent = detailComponent;
    const tile = (
      <div
        className={classNames(
          'rounded-md border-2 border-gray-100 h-full',
          selected ? '' : effects,
          {
            'outline outline-emerald-500 outline-2 outline-offset-2': selected,
            'outline outline-blue-500 outline-2 outline-offset-2':
              selectable && selected && !showMenu,
            'hover:outline hover:outline-emerald-500 hover:outline-2 hover:outline-offset-2':
              selectable && !selected && !showMenu,
            'aspect-square': !footer,
            [LOADING_BLOCK_STYLE]: !image,
          },
        )}
      >
        <button
          type="button"
          className={classNames(
            'w-full relative',
            selectable ? 'cursor-pointer' : 'cursor-not-allowed',
          )}
          onClick={() => image && selectable && onClick?.(image)}
          aria-expanded={selected}
        >
          {image ? (
            <ProtectedImage
              key={image.previewImages.small.url}
              url={image.previewImages.small.url}
              sensitive={image.sensitive}
              coactiveImageId={image.coactiveImageId}
              className={classNames('w-full h-full aspect-square', {
                'rounded-t-md rounded-b-none': footer,
              })}
              rounded
            />
          ) : (
            <div className="w-full h-full aspect-square bg-gray-300 rounded-md" />
          )}
          {image?.label && (
            <Label label={image.label} className="absolute top-0 right-0" />
          )}
          {(icon || tileButton) && (
            <div className="absolute top-0 right-0">
              {icon && icon!}
              {image && tileButton?.(image)}
            </div>
          )}
          {selectStyle && (
            <SelectedIcon
              selected={selected}
              order={selectStyle === 'numbered' ? order : undefined}
            />
          )}
          {disabledMessage && (
            <div className="lg:text-xs text-[10px] font-medium text-white absolute bottom-2 left-2 rounded-md shadow-md bg-black/60 p-1">
              {disabledMessage}
            </div>
          )}
          {assetType === AssetType.Keyframe ? (
            <div className="absolute bottom-2 right-2 rounded-full shadow-md bg-white p-2">
              <VideoCameraIcon className="h-4 w-4 fill-gray-600" />
            </div>
          ) : undefined}
        </button>
        {footer && image && footer(image)}
      </div>
    );

    if (!showMenu) {
      return (
        <li key={image?.coactiveImageId}>
          {tile}
          {DetailComponent && image && <DetailComponent data={data} />}
        </li>
      );
    }
    return (
      <Popover key={image?.coactiveImageId} className="relative">
        <Popover.Button as="li">{tile}</Popover.Button>

        <Popover.Panel
          className={classNames(
            !popoverClassName &&
              'w-40 origin-top-right absolute right-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-30 mt-2 left-0',
            popoverClassName,
          )}
        >
          {image && PopoverComponent ? (
            <PopoverComponent image={image} />
          ) : (
            <img src="/solutions.jpg" alt="" />
          )}
        </Popover.Panel>
      </Popover>
    );
  },
  propsAreEqual,
);

Tile.defaultProps = {
  popoverComponent: undefined,
  popoverClassName: undefined,
  footer: undefined,
  detailComponent: undefined,
  assetType: AssetType.Image,
  data: undefined,
  selectStyle: undefined,
  order: undefined,
  icon: undefined,
  disabledMessage: undefined,
  tileButton: undefined,
};

export default Tile;
