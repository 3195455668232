import { AssetResponse } from 'api/generated';
import AdvancedSearch from 'components/DatasetSearch/AdvancedSearch';
import { SearchMode } from 'components/DatasetSearch/AdvancedSearch/types';
import { DropdownOption } from 'components/Dropdown';
import { AssetTypeOption } from 'components/TextSearch';
import { getSearchImagesQueryKey } from 'pages/datasets/detail/components/queries';
import React from 'react';
import { useQueryClient } from 'react-query';
import DatasetTextSearch from './DatasetTextSearch';

interface DatasetSearchProps {
  datasetId: string;
  onClick?: (img: AssetResponse) => void;
  searchMode: SearchMode;
  searchModeOptions: DropdownOption<SearchMode>[];
  setSearchMode?: (mode: SearchMode) => void;
  defaultAssetType?: AssetTypeOption;
  setTextSearchActive?: (active: boolean) => void;
  labelResults?: (positive: string[], negative: string[]) => void;
  isLabelingResults?: boolean;
  hideHeader?: boolean;
  setPopupHeight?: (height: number) => void;
  searchContainerClassName?: string;
  setIsUsingSearchEndpoint?: (isUsingSearchEndpoint: boolean) => void;
  setDatasetViewAssetType?: (datasetViewAssetType: AssetTypeOption) => void;
  isUsingSearchEndpoint?: boolean;
  datasetViewAssetType?: AssetTypeOption;
  searchActive?: boolean;
  hasToggleGridResults?: boolean;
  disabledResults?: AssetResponse[];
  disabledResultMessage?: string;
}

const DatasetSearch: React.FC<DatasetSearchProps> = function DatasetSearch({
  datasetId,
  onClick,
  searchMode,
  searchModeOptions,
  setSearchMode,
  defaultAssetType,
  setTextSearchActive,
  labelResults,
  isLabelingResults,
  hideHeader,
  setPopupHeight,
  searchContainerClassName,
  setIsUsingSearchEndpoint,
  setDatasetViewAssetType,
  datasetViewAssetType,
  isUsingSearchEndpoint,
  searchActive,
  hasToggleGridResults,
  disabledResultMessage,
  disabledResults,
}) {
  const queryClient = useQueryClient();

  const switchSearchMode = (mode: SearchMode) => {
    queryClient.resetQueries(getSearchImagesQueryKey(datasetId));
    setSearchMode?.(mode);
    setTextSearchActive?.(false);
  };

  return (
    <>
      {searchModeOptions?.find((sto) => sto.value === 'advanced-visual') &&
        searchMode === 'advanced-visual' &&
        datasetId && (
          <AdvancedSearch
            datasetId={datasetId}
            setSearchMode={switchSearchMode}
            searchModeOptions={searchModeOptions}
            setPopupHeight={setPopupHeight}
            searchContainerClassName={searchContainerClassName}
          />
        )}
      {(Boolean(searchModeOptions?.find((sto) => sto.value === 'visual')) ||
        Boolean(searchModeOptions?.find((sto) => sto.value === 'audio'))) &&
        (searchMode === 'visual' || searchMode === 'audio') && (
          <DatasetTextSearch
            setIsUsingSearchEndpoint={setIsUsingSearchEndpoint}
            datasetId={datasetId}
            onClick={onClick}
            searchType={searchMode}
            searchMode={searchMode}
            setSearchMode={switchSearchMode}
            searchModeOptions={searchModeOptions}
            setSearchActive={setTextSearchActive}
            labelResults={labelResults}
            isLabelingResults={isLabelingResults}
            hideHeader={hideHeader}
            defaultAssetType={defaultAssetType}
            searchContainerClassName={searchContainerClassName}
            setDatasetViewAssetType={setDatasetViewAssetType}
            isUsingSearchEndpoint={isUsingSearchEndpoint}
            datasetViewAssetType={datasetViewAssetType}
            searchActive={searchActive}
            hasToggleGridResults={hasToggleGridResults}
            disabledResults={disabledResults}
            disabledResultMessage={disabledResultMessage}
          />
        )}
    </>
  );
};

DatasetSearch.defaultProps = {
  onClick: undefined,
  setSearchMode: undefined,
  setTextSearchActive: undefined,
  defaultAssetType: undefined,
  labelResults: undefined,
  isLabelingResults: false,
  hideHeader: false,
  setPopupHeight: undefined,
  searchContainerClassName: undefined,
  setIsUsingSearchEndpoint: undefined,
  setDatasetViewAssetType: undefined,
  isUsingSearchEndpoint: false,
  datasetViewAssetType: undefined,
  searchActive: false,
  hasToggleGridResults: undefined,
  disabledResultMessage: undefined,
  disabledResults: undefined,
};

export default DatasetSearch;
