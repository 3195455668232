import classNames from 'classnames';
import React, { JSX, KeyboardEvent, useRef } from 'react';

interface PrefixListInputProps {
  prefixList: JSX.Element[];
  value: string;
  onChange: (value: string) => void;
  label?: string;
  showPrefixCount?: boolean;
  placeholder?: string;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const PrefixListInput: React.FC<PrefixListInputProps> = ({
  prefixList,
  value,
  onChange,
  onKeyDown,
  placeholder,
  label,
  showPrefixCount,
}: PrefixListInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDivClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget && inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Avoids warning for div onClick event
  const handleDivKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.key === 'Enter' || e.key === ' ') && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const hasHeader = label || showPrefixCount;

  return (
    <>
      {hasHeader && (
        <div
          className={classNames(
            'mb-2 flex items-center',

            {
              'justify-between': showPrefixCount && label,
              'justify-start': label,
              'justify-end': showPrefixCount,
            },
          )}
        >
          {label && (
            <h2 className="text-start text-lg font-semibold text-gray-800">
              {label}
            </h2>
          )}
          {showPrefixCount && prefixList.length > 0 && (
            <p>{prefixList.length}</p>
          )}
        </div>
      )}
      <div
        className=" overflow-y-auto h-full px-4 py-2 bg-white shadow-sm w-full sm:text-sm border border-gray-200 rounded-md"
        onClick={handleDivClick}
        onKeyDown={handleDivKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className="flex flex-wrap gap-2">
          {prefixList}
          <input
            ref={inputRef}
            onKeyDown={onKeyDown}
            className="flex-grow text-sm p-0 border-transparent focus:border-transparent focus:ring-0"
            placeholder={placeholder}
            type="text"
            onChange={(e) => onChange(e.target.value)}
            value={value}
          />
        </div>
      </div>
    </>
  );
};

PrefixListInput.defaultProps = {
  showPrefixCount: undefined,
  label: undefined,
  placeholder: undefined,
  onKeyDown: undefined,
};

export default PrefixListInput;
